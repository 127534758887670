
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPage } from "../interface";

type TFilter = {
    status : boolean[]
    resource : boolean[]
    entity : boolean[]
  }

const initialState: IPage = {
    navPage: 'dashboard',
    activePage: 0,
    loading: false,
    errors: false,
    filter : {
        status:[false, false, false, false],
        resource:[false, false, false],
        entity: [false, false]
    },
    pages:{
        currPage: 0,
        arrPages: [1, 2 , 3]
    }
}

export const pageSlice = createSlice({

    name: 'pages',
    initialState,
    reducers: {
        navPageReducer: (state, action: PayloadAction<string>) => {
            state.navPage = action.payload
        },
        activePageReducer: (state, action: PayloadAction<number>) => {
            state.activePage = action.payload
        },
        loadingReducer: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        errorsReducer: (state, action: PayloadAction<boolean>) => {
            state.errors = action.payload
        },
        giveFilterReducer: (state, action: PayloadAction<TFilter>) => {
            state.filter = {...action.payload}
        },
        pageReducer: (state, action: PayloadAction<{
            currPage: number
            arrPages: number[]
        }>) => {
            state.pages = {...action.payload}
        }
    },
}

)
export const { navPageReducer, activePageReducer, loadingReducer, 
    giveFilterReducer, pageReducer, errorsReducer } = pageSlice.actions;
export default pageSlice.reducer