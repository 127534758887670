import React from 'react'
import s from './reports.module.css'
import Calendar from '../Calendar/Calendar'

type Props = {}

function LeftFilter(props: Props) {

    
    
    return (<div className={s.choiceReportsRight}>
        <div className={s.choiceReports}>
            <div className={s.titleReports}>
                <span className={s.title}>Выбор даты</span>
            </div>
            <div className={s.itemsReports}>
                <Calendar />
            </div>

        </div>

       {/* <div className={s.choiceReports}>
            <div className={s.titleReports}>
                <span className={s.title}>Принадлежность</span>

            </div>
            <div className={s.itemsReports}>
                <div className={s.checkReports}>
                    <input type="checkbox" id="individual" name="individual" className={s.radio}
                        onChange={(e) => { onChange("individual", e.currentTarget.checked, 0) }}
                    />
                    <span className={s.item}>физическое лицо</span>
                </div>
                <div className={s.checkReports}>
                    <input type="checkbox" id="legal" name="legal" className={s.radio}
                        onChange={(e) => { onChange("legal", e.currentTarget.checked, 1) }}
                    />
                    <span className={s.item}>юридическое лицо и ИП</span>
                </div>

            </div>
        </div>

        <div className={s.choiceReports}>
            <div className={s.titleReports}>
                <span className={s.title}>Тип прибора</span>

            </div>
            <div className={s.itemsReports}>
                <div className={s.checkReports}>
                    <input type="checkbox" id="cold" name="cold" className={s.radio}
                        onChange={(e) => { onChange("cold", e.currentTarget.checked, 2 )}}
                    />
                    <span className={s.item}>счетчик воды холодной </span>
                </div>
                <div className={s.checkReports}>
                    <input type="checkbox" id="hot" name="hot" className={s.radio}
                        onChange={(e) => { onChange("hot", e.currentTarget.checked, 3) }}
                    />
                    <span className={s.item}>счетчик воды горячий </span>
                </div>
                <div className={s.checkReports}>
                    <input type="checkbox" id="heat" name="heat" className={s.radio}
                        onChange={(e) => { onChange("heat", e.currentTarget.checked, 4) }}
                    />
                    <span className={s.item}>счетчик тепла</span>
                </div>
                <div className={s.checkReports}>
                    <input type="checkbox" id="elect" name="elect" className={s.radio}
                        onChange={(e) => { onChange("elect", e.currentTarget.checked, 5) }}
                    />
                    <span className={s.item}>счетчик электрической энергии</span>
                </div>
            </div>

        </div>*/}
    </div>
    )
}

export default LeftFilter