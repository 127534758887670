import React, { useState } from 'react'
import s from './login.module.css'
type Props = {}

const Reg = (props: Props) => {
    const [valueLog, setValueLog] = useState('')
    const [valuePass, setValuePass] = useState('')

    const handleSubmit =
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()
        }
    return (
        <div className={s.formLog}>
            <form onSubmit={handleSubmit} className={s.inputLog}>
                <input type="email" value={valueLog}
                    required
                    onChange={(e) => { setValueLog(e.currentTarget.value) }}

                />
                <input type="password"
                    value={valuePass}
                    size={7}
                    minLength={5}
                    maxLength={7}
                    onChange={(e) => { setValuePass(e.currentTarget.value) }}
                    required

                />
                <input type="password"
                    size={7}
                    pattern={valuePass}
                    title='Не совпадает с предыдущим значением'
                    required

                />
                <button type='submit' >Зарегистрироваться</button>

            </form>
        </div>
    )
}

export default Reg