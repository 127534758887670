import { IDataRes } from '../interface';
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type TData = {
    data: IDataRes
    activeAdress: number | undefined
    offset: number,
    limit: number,
    queryString: string
    
}

const initialState: TData = {
    data: {
        data: [{
            parameter_id: '',
            address: [{ place_name: '', place_id: '', place_type_name: '' },
            { place_name: '', place_id: '', place_type_name: '' },
            { place_name: '', place_id: '', place_type_name: '' },
            { place_name: '', place_id: '', place_type_name: '' }
            ],
            network_address: '',
            sensor_number: '',
            resource: '',
            parameter: '',
            device_name: '',
            last_seen: '',
            last_value: 0,
            status: '',
            note: '',
            entity: '',
            bat: 0

        }],
        success: true,
        message: '',
        total: 100,
    },
    activeAdress: undefined,
    offset: 0,
    limit: 20,
    queryString:''
}

export const dataSlice = createSlice({

    name: 'data',
    initialState,
    reducers: {
        dataReducer: (state, action: PayloadAction<IDataRes>) => {
            state.data = action.payload
        },
        pagesNumberReducer: (state, action: PayloadAction<{offset:number, limit:number}>) => {
            state.offset = action.payload.offset
            state.limit = action.payload.limit
        },
        filerReducer: (state, action: PayloadAction<string>) => {
            state.queryString = action.payload
        }, 
    },
}

)
export const { dataReducer, pagesNumberReducer , filerReducer} = dataSlice.actions;
export default dataSlice.reducer;