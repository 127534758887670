
import { PayloadAction, createSlice } from "@reduxjs/toolkit";


type TAddress = {
    cities: [string, string, boolean?, boolean?][]
    defaultCites: [string, string, boolean?, boolean?][]
    activeCity: [string, string | undefined, string | undefined]
    streets: [string, string, boolean, string][]
    houses: [string, string, boolean, string][]
    flats: [string, string, boolean, string][]
    typeFlat: string
    typeSensor: string

}

const initialState: TAddress = {
    cities: [['Минск', '1d678670-3617-11ef-91b8-0000000004c1', false, true], ['Брестская обл.', '', true, false], ['Витебская обл.', '', true, false],
    ['Гомельская обл.', '', true, false], ['Гродненская обл.', '', true, false],
    ['Минская обл.', '1d678720-3617-11ef-91b8-000000001bd3', true, true],
    ['Могилевская обл', '', true, false], ['Без адреса', '1d678519-3617-11ef-91b8-000000001348', true, true]],
    defaultCites: [['','', false, false]], //[place_name, place_id, place_type_name = '', children]
    activeCity: ['1d678670-3617-11ef-91b8-0000000004c1', undefined, undefined],
    streets: [['', '', false, '']],
    houses: [['', '', false, '']],
    flats: [['', '', false, '']], //  [place_name, place_id, children, place_type_name]
    typeFlat: 'квартира',
    typeSensor: 'cold'

}

export const adressSlice = createSlice({

    name: 'adress',
    initialState,
    reducers: {
        citesReducer: (state, action: PayloadAction<[string, string, boolean?, boolean?][]>) => {
            state.cities = action.payload
        },
        citesDefaultReducer: (state, action: PayloadAction<[string, string, boolean?, boolean?][]>) => {
            state.defaultCites = action.payload
        },
        activeAddressReducer: (state, action: PayloadAction<[string, string | undefined, string | undefined]>) => {
            state.activeCity = [...action.payload]

        },
        streetsReducer: (state, action: PayloadAction<[string, string, boolean, string][]>) => {
            state.streets = action.payload
        },
        housesReducer: (state, action: PayloadAction<[string, string, boolean, string][]>) => {
            state.houses = [...action.payload]
        },
        setFlatsReducer: (state, action: PayloadAction<[string, string, boolean, string][]>) => {
            state.flats = [...action.payload]

        },
        flatReduser: (state, action: PayloadAction<string>) => {
            state.typeFlat = action.payload

        },
        sensorReduser: (state, action: PayloadAction<string>) => {
            state.typeSensor = action.payload

        },

    },
}

)
export const { citesReducer, citesDefaultReducer, activeAddressReducer,
    streetsReducer, housesReducer, setFlatsReducer, flatReduser, sensorReduser } = adressSlice.actions;
export default adressSlice.reducer;