import React from 'react'
import s from './reports.module.css'
import AddressFilter from './AddressFilter'
import ReportWindow from './ReportWindow'
import LeftFilter from './LeftFilter'




type Props = {}

const Reports = (props: Props) => {
  return (
    <div className={s.reports}>

      <h2>Отчеты</h2>

      <div className={s.blockChoiceReportsTop}>
        <div className={s.choiceReportsLeft}>
          <AddressFilter />
        </div>

        <LeftFilter />


      </div>

      <ReportWindow />
    </div>




  )
}

export default Reports