import React, { useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import { TfiSearch } from "react-icons/tfi";
import s from './search.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/Hooks';
import { useGetPlacesMutation } from '../../../app/slices/sliceAddressApi';
import { activeAddressReducer, citesReducer } from '../../../app/slices/adressSlice';
import { errorsReducer, loadingReducer } from '../../../app/slices/pagesSlice';


type Props = {
  name: string
  show?: boolean
}

const SearchCity = ({ name, show }: Props) => {
  const token = useAppSelector(state => state.login.token)
  const [showSearch, setShowSearch] = useState(false)
  const cities = useAppSelector(state => state.address.cities)
  const defaultCities = useAppSelector(state => state.address.defaultCites)
  const dispatch = useAppDispatch()
  const [getData, { isLoading, isError }] = useGetPlacesMutation()
  const [input, setInput] = useState<string>('')

  const options = {
    includeScore: true,
    keys: ['0']
  }

  const fuse = new Fuse(cities, options)
  const result = fuse.search(input)
  useEffect(() => {
    dispatch(loadingReducer(isLoading))
  }, [isLoading, dispatch])
  useEffect(() => {
    dispatch(errorsReducer(isError))
  }, [isError, dispatch])
  useEffect(() => {
    if (show) setShowSearch(true)
  }, [show])

  useEffect(() => {
    if (showSearch) {
      let a: [string, string, boolean?, boolean?][] = [['', '', false, false]]


      cities.forEach((el, i) => {
        switch (el[2]) {
          case false:
            a.push([el[0], el[1], false, true])
            break
          case true:
            if (el[3]) {
              getData({ token, placeId: el[1] }).unwrap()
                .then(async (res) => {

                  let b: [string, string, boolean?, boolean?][] = res.data.map(el => [el.place_name, el.place_id, false, true])
                  a = [...a, ...b].filter(item => item[3])
                  dispatch(citesReducer(a))

                }
                )

            }
            break
          default:
            break;
        }
      })

    }



  }, [cities, getData, dispatch, showSearch, defaultCities, token])

  useEffect(() => {
    if (!showSearch) {
      dispatch(citesReducer([...defaultCities]))
      dispatch(activeAddressReducer(['1d678670-3617-11ef-91b8-0000000004c1', undefined, undefined]))
    }
  }, [showSearch, dispatch, defaultCities])


  const onClick = async () => {

    setShowSearch(prev => !prev)

  }
  useEffect(() => {
    if (input.length > 2) {

      let a = result.filter(item => (item.score && item.score < 0.5)).map(el => el.item)


      dispatch(citesReducer(a))

    } else dispatch(citesReducer(defaultCities))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input])
  return (
    <div className={s.headerTytle}>
      <span style={showSearch ? { display: 'none' } : undefined}>{name}</span>
      <input className={showSearch ? s.search : s.searchHidden} type="text" id="Search" placeholder={name} title="Type in a name"
        value={input}
        onChange={(e) => {
          setInput(e.currentTarget.value);
        }}
      />
      <TfiSearch onClick={() => {
        onClick()
        setInput('')
      }} className={s.iconLupa} />
    </div>
  )
}

export default SearchCity