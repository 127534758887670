import React, { useState } from 'react'
import s from './search.module.css'
import { TfiSearch } from "react-icons/tfi"
import { filerReducer } from '../../../app/slices/dataSlice'
import { useDispatch } from 'react-redux'

type Props = {}

function MainSearch(props: Props) {

  const dispatch = useDispatch()
  const [showSearch, setShowSearch] = useState(false)
  const [input, setInput] = useState('')

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.currentTarget.value)

  }

  const handlerSubmit = () => {
    setInput('')
    setShowSearch(false)
    dispatch(filerReducer('&query='+input))
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') handlerSubmit()

  }



  return (
    <div className={s.searchBlock}>
      <input className={showSearch ? s.search : s.searchHidden} type="text" id="Search"
        placeholder="Поиск" title="Type in a name" value={input} onChange={onChange}
        onKeyDown={onKeyDown} />
      <div className={s.lupa}>
        <TfiSearch className={s.lupaIcon} onClick={() => { !showSearch ? setShowSearch(true) : handlerSubmit() }} />
      </div>

    </div>
  )
}

export default MainSearch