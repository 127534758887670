import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { urlServer } from '../../config'
import { IMaps, IPlacesID, ISensorsId } from '../interface'


type TQuery = {
    token: string | null
    placeId?: string
}


export const addressApi = createApi({
    reducerPath: 'addressApi',

    baseQuery: fetchBaseQuery({
        baseUrl: urlServer,

    }),

    endpoints: (build) => ({
        getPlaces: build.mutation<IPlacesID, TQuery>({
            query: ({ token, placeId }) => ({
                url: `/place/tree/${placeId}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            }),

        }),
        getTree: build.mutation<IPlacesID, TQuery>({
            query: ({ token }) => ({
                url: `/place/tree`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            }),

        }),
        getMap: build.mutation<IMaps, {token:string| null, total: number}>({
            query: ( {token, total} ) => ({
                url: `/dashboard`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            }),

        }), 
        getSensorsFlat: build.mutation<ISensorsId, TQuery>({
            query: ({ token, placeId }) => ({
                url: `/object/${placeId}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            }),

        }),

    })
    
})
export const { useGetPlacesMutation, useGetMapMutation, useGetSensorsFlatMutation , useGetTreeMutation} = addressApi;

