import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import  MainPage  from './components/MainPage';
import Login from './components/login/Login';
import { useAppSelector } from './app/Hooks';
import Error404 from './components/Error404';
import Error500 from './components/Error500';
import MaintenanceMode from './components/MaintenanceMode';
import ComingSoon from './components/ComingSoon';

//import { Error404 } from 'components/Error404';
const App: React.FC = () => {
const width = window.screen.width  
const login = useAppSelector(state => state.login)
const navigate = useNavigate()
 
 useEffect(() => {
    if(!login.user.email) navigate('/')
 }, [login.user.email, navigate])

 
  return (
    <div className="App">
     {width >= 1024 ? <Routes>
      {/*  <Route path='' element={<Login />}></Route>
        {login.user.role !== 'ADMIN' ? <Route path='/main' element={<Error404 />}></Route> :
          
        }*/}
        <Route path='/' element={<Login/>}></Route>      
        <Route path='/main' element={<MainPage />}></Route>
        <Route path='/404' element={<Error404 /> }></Route>
        <Route path='/500' element={<Error500 />}></Route>
        <Route path='/soon' element={<ComingSoon />}></Route>
        <Route path='/mainten' element={<MaintenanceMode />}></Route>
      </Routes> : 
      <h3 className='App_text'>Для работы приложения необходимо разрешение экрана не менее 1024х768</h3>
      }


    </div>
  );
}

export default App;
