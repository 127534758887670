import { setupListeners } from '@reduxjs/toolkit/query';
import { configureStore } from '@reduxjs/toolkit'
import loginReduser from './slices/loginSlice';
import pageReduser from './slices/pagesSlice';
import dataReduser from './slices/dataSlice';
import adressReducer from './slices/adressSlice'
import repoetReduser from './slices/reportSlice'
import { loginApi } from './slices/sliceLogApi'
import { addressApi } from './slices/sliceAddressApi'


export const store = configureStore({
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    [addressApi.reducerPath]: addressApi.reducer,
    login: loginReduser,
    pages: pageReduser,
    data: dataReduser,
    address: adressReducer,
    report: repoetReduser
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      loginApi.middleware,
      addressApi.middleware,
    )
  ,

})
setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch