import React, { useEffect, useState } from 'react'
import s from './houses.module.css'
import { useGetPlacesMutation } from '../../../app/slices/sliceAddressApi'
import { useAppDispatch, useAppSelector } from '../../../app/Hooks'
import { activeAddressReducer, flatReduser, housesReducer } from '../../../app/slices/adressSlice'
import { TfiPlus } from "react-icons/tfi";
import { TfiMinus } from "react-icons/tfi";
import { errorsReducer, loadingReducer } from '../../../app/slices/pagesSlice'


type Props = {
  placeId: string | undefined
  token: string | null
}

const Houses = ({ placeId, token }: Props) => {
  const dispatch = useAppDispatch()
  const { activeCity, typeFlat, houses } = useAppSelector(state => state.address)

  const [getData, {isLoading, isError}] = useGetPlacesMutation()

  const [activePlus, setActivePlus] = useState<number | null>(null)

 
  useEffect(() => {
    dispatch(loadingReducer(isLoading))
  }, [isLoading, dispatch])
  useEffect(() => {
    dispatch(errorsReducer(isError))
  }, [isError, dispatch])
  useEffect(() => {
    if (placeId) {
    
      
      getData({ token, placeId }).unwrap()
        .then(res => {
          if (res.data.length > 0) {
            dispatch(housesReducer(res.data.map(el => [el.place_name, el.place_id, el.children, el.place_type_name])))

          }
          else dispatch(housesReducer([['', '', false, '']]))

        }
        )
    } else { dispatch(housesReducer([['', '', false, '']])) 
    }
    setActivePlus(null)
  }, [token, placeId, getData, dispatch])

  const onClick = (id: string, typeFlats: string) => {
    dispatch(activeAddressReducer([activeCity[0], activeCity[1], id]))
    dispatch(flatReduser(typeFlats))
  }



  const house = houses.map((el, i) => {


    return (
      <div key={el[1]} className={s.housesList}>
        <div className={s.blockHousesList}>
          <span className={s.houseEl} >{el[3] + ' '}{el[0]}</span>
          {(i !== activePlus) ? <TfiPlus onClick={() => {
            setActivePlus(i)
            dispatch(activeAddressReducer([activeCity[0], activeCity[1], undefined]))
          }} className={s.iconAddress} /> :
            <TfiMinus className={s.iconAddress} onClick={() => {
              setActivePlus(null)
              dispatch(activeAddressReducer([activeCity[0], activeCity[1], undefined]))
            }
            } />}
        </div>
        {(i === activePlus) && <>

          <div style={(el[1] === activeCity[2] && typeFlat === 'Квартира') ? { backgroundColor: '#2c2c2c', color: '#fff' } : undefined}
            className={s.itemsHouseEl}
            onClick={() => { onClick(el[1], 'Квартира') }}>
            <span style={el[2] ? { cursor: 'pointer' } : undefined} className={s.itemsHouse}>квартиры</span>
          </div>
          <div style={(el[1] === activeCity[2] && typeFlat === 'Empty') ? { backgroundColor: '#2c2c2c', color: '#fff' } : undefined} className={s.itemsHouseEl}
            onClick={() => { onClick(el[1], 'Empty') }}>
            <span style={el[2] ? { cursor: 'pointer' } : undefined} className={s.itemsHouse}>места общего пользования</span>
          </div>
          <div style={(el[1] === activeCity[2] && typeFlat === 'Офис') ? { backgroundColor: '#2c2c2c', color: '#fff' } : undefined} className={s.itemsHouseEl}
            onClick={() => { onClick(el[1], 'Офис') }}>
            <span className={s.itemsHouse}>административные помещения</span>
          </div>
        </>}
      </div>
    )
  })
  return (
    <div className={s.houses}>
      {(houses[0][0].length > 0) && house}

    </div>
  )
}

export default Houses