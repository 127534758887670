import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { urlServer } from '../../config'
import { IDataRes, IReport, ISensor, IUser, Ilogin } from '../interface'


type TQuery = {
    offset: number
    limit: number
    token: string | null
    queryString: string
}

export const loginApi = createApi({
    reducerPath: 'loginApi',

    baseQuery: fetchBaseQuery({
        baseUrl: urlServer,

    }),

    endpoints: (build) => ({
        getToken: build.mutation<IUser, Ilogin>({
            query: ({ username, password }) => ({
                url: '/auth',
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: { username, password },

            }),

        }),


        getDashboard: build.query<IDataRes, TQuery>({
            query: ({ token, offset, limit, queryString }) => ({
                url: `/sensor?offset=${offset}&limit=${limit}${queryString}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },

            }),
        }),
        getSensor: build.mutation<ISensor, { token: string | null, id: string }>({
            query: ({ token, id }) => ({
                url: '/sensor/' + id,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            })

        }),
        getReport: build.mutation<IReport, { token: string | null, id: string, begin: string, end:string, offset: number}>({
            query: ({ token, id, begin, end, offset }) => ({
                url: `/object/${id}/report?offset=${offset}&limit=100&begin_date=${begin}&end_date=${end}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            })

        })

    })
})
export const { useGetTokenMutation, useGetDashboardQuery, useGetSensorMutation, useGetReportMutation } = loginApi;

