import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker"
import { registerLocale } from "react-datepicker";
import { ru } from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch } from 'react-redux';
import { getOffsetReducer, getTimeReducer } from '../../../app/slices/reportSlice';
import s from './/calendar.module.css'


registerLocale('ru', ru)
type Props = {}

function Calendar(props: Props) {

  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState<Date | null | undefined>(new Date(new Date().getFullYear(),
    new Date().getMonth(), new Date().getDate() - 1))
  const [endDate, setEndDate] = useState<Date | null | undefined>(new Date());



  useEffect(() => {
    if (startDate && endDate && (endDate > startDate)) {
      dispatch(getOffsetReducer(0))
      dispatch(getTimeReducer([startDate.toLocaleDateString('en-CA'), endDate.toLocaleDateString('en-CA')]))
    }
  }, [startDate, endDate, dispatch])
  return (
    <>

      <div className={s.itemsReportsDate}>
        <span className={s.item}>начало периода</span>

        <div className={s.startDate}>
          <DatePicker

            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate === null ? undefined : startDate}
            endDate={endDate === null ? undefined : endDate}
            locale="ru"
            showIcon
            toggleCalendarOnIconClick

          />
        </div>

      </div>


      <div className={s.itemsReportsDate}>
        <span className={s.item}>конец периода</span>
        <div className={s.endDate}>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            startDate={startDate === null ? undefined : startDate}
            endDate={endDate === null ? undefined : endDate}
            selectsEnd
            locale="ru"
            showIcon
            toggleCalendarOnIconClick
          />
        </div>


      </div>





    </>

  )
}

export default Calendar