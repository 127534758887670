import React, { useState } from 'react'
import s from './filter.page.module.css'
import { TfiClose } from "react-icons/tfi";
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import { TfiFilter } from "react-icons/tfi";
import { filerReducer } from '../../app/slices/dataSlice';
import { giveFilterReducer } from '../../app/slices/pagesSlice';

let queryString =''
type Props = {
  close: () => void
}

const FiltrPage = ({ close }: Props) => {

  const dispatch = useAppDispatch()  
  const {status, resource, entity} = useAppSelector(state => state.pages.filter)
  const [statusS, setStatus] = useState(status)
  const [resourceS, setResource] = useState(resource)
  const [entityS, setEntity] = useState(entity)
  const [show, setShow] = useState(true)
  const itemStatus = ['online', 'offline', 'warning', 'error']
  const itemResurce = ['cold', 'hot', 'heat']
  const itemEntity = ['individual', 'legal']


  
  const changeStatus = (i: number, arrayData: boolean[], typeArray: string) => {
    let a = [...arrayData]
    a.splice(i, 1, !arrayData[i])
    if (typeArray === 'status') setStatus(a)
    if (typeArray === 'resource') setResource(a)
    if (typeArray === 'entity') setEntity(a)
  }

  const hidden = () => {
    setShow(false)
    setTimeout(() => {
      close()
    }, 1100)
  }

  const creatQuery = () => {

    let statusString = ''
    statusS.forEach((el, i) => {
      if (el) statusString = statusString + `&status=${itemStatus[i]}`
    })
    let resourceString = ''
    resourceS.forEach((el, i) => {
      if (el) resourceString = resourceString + `&resource=${itemResurce[i]}`
    })
    let entityString = ''
    entityS.forEach((el, i) => {
      if (el) entityString = entityString + `&entity=${itemEntity[i]}`
    })
    queryString = statusString + resourceString + entityString
    dispatch(filerReducer(queryString))
    dispatch(giveFilterReducer({status: [...statusS], resource: [...resourceS], entity: [...entityS]}))
  }


  return (
    <div className={show ? s.filter : s.filterHidden}>
      <div className={s.header}>
        <div className={s.headerTitle}>
          <TfiFilter className={s.filterHeader}/>
          <span className={s.filterTitle}>Фильтр</span>
        </div>
        <TfiClose className={s.iconClose} onClick={hidden} />
      </div>
      <div className={s.filterCatalog}>

        <div className={s.filterStatus}>
          <div className={s.statusText}>
            <span className={s.statusTxt}>01 Cтатус устройства</span>

          </div>
          <div className={s.statusItems}>
            <div className={s.statusBlock}>
              <input type="checkbox" id="status0" name="status0" checked={statusS[0]}
              onChange={() => { changeStatus(0,  statusS, 'status' )
               }} className={s.radio} />
              <span className={s.filterTxt}>Online</span>
            </div>
            <div className={s.statusBlock}>
              <input type="checkbox" id="status1" name="status1" checked={statusS[1]}
              onChange={() => { changeStatus(1,  statusS, 'status' )
               }} className={s.radio} />
              <span className={s.filterTxt}>Offline</span>
            </div>
            <div className={s.statusBlock}>
              <input type="checkbox" id="status2" name="status2" checked={statusS[2]}
              onChange={() => { changeStatus(2,  statusS, 'status' )
               }} className={s.radio} />
              <span className={s.filterTxt}>Warning</span>
            </div>
            <div className={s.statusBlock}>
              <input type="checkbox" id="status3" name="status3" checked={statusS[3]}
              onChange={() => { changeStatus(3,  statusS, 'status' )
               }} className={s.radio} />
              <span className={s.filterTxt}>Еrror</span>
            </div>
          </div>
        </div>

        <div className={s.filterResurs}>
          <div className={s.statusText}>
            <span className={s.statusTxt}>02 Тип ресурса</span>

          </div>
          <div className={s.statusItems}>
            <div className={s.statusBlock}>
              <input type="checkbox" id="resource0" name="resource0" checked={resourceS[0]}
              onChange={() => { changeStatus(0,  resourceS, 'resource' )
               }} className={s.radio} />
              <span className={s.filterTxt}>Холодная вода</span>
            </div>
            <div className={s.statusBlock}>
            <input type="checkbox" id="resource1" name="resource1" checked={resourceS[1]}
              onChange={() => { changeStatus(1,  resourceS, 'resource' )
               }} className={s.radio} />
              <span className={s.filterTxt}>Горячая вода</span>
            </div>
            <div className={s.statusBlock}>
            <input type="checkbox" id="resource2" name="resource2" checked={resourceS[2]}
              onChange={() => { changeStatus(2,  resourceS, 'resource' )
               }} className={s.radio} />
              <span className={s.filterTxt}>Тепло</span>
            </div>
          </div>
        </div>

        <div className={s.filterSubject}>
          <div className={s.statusText}>
            <span className={s.statusTxt}>03 Тип субъекта</span>

          </div>
          <div className={s.statusItems}>
            <div className={s.statusBlock}>
              <input type="checkbox" id="entity0" name="entity0" checked={entityS[0]}
              onChange={() => { changeStatus(0,  entityS, 'entity' )
               }} className={s.radio} />
              <span className={s.filterTxt}>Физические лица</span>
            </div>
            <div className={s.statusBlock}>
              <input type="checkbox" id="entity1" name="entity1" checked={entityS[1]}
              onChange={() => { changeStatus(1,  entityS, 'entity' )
               }} className={s.radio} />
              <span className={s.filterTxt}>Юридические лица и ИП</span>
            </div>
          </div>
        </div>

        <button className={s.filterButton} onClick={() => {creatQuery(); close()}}>Применить фильтр</button>
      </div>
    </div>
  )
}

export default FiltrPage