import React, { useEffect } from 'react'
import s from './streets.module.css'
import { useGetPlacesMutation } from '../../../app/slices/sliceAddressApi'
import { useAppDispatch, useAppSelector } from '../../../app/Hooks'
import { activeAddressReducer, streetsReducer } from '../../../app/slices/adressSlice'
import { errorsReducer, loadingReducer } from '../../../app/slices/pagesSlice'


type Props = {
  placeId: string | undefined
  token: string | null
}

const Streets = ({ placeId, token }: Props) => {

  const dispatch = useAppDispatch()
  const action = useAppSelector(state => state.address.activeCity)
  const streetItem = useAppSelector(state => state.address.streets)
  const [getData, { isLoading, isError }] = useGetPlacesMutation()

  useEffect(() => {
    dispatch(loadingReducer(isLoading))
  }, [isLoading, dispatch])
  useEffect(() => {
    dispatch(errorsReducer(isError))
  }, [isError, dispatch])
  useEffect(() => {
    if (placeId) getData({ token, placeId }).unwrap()
      .then(res => {
        dispatch(streetsReducer(res.data.map(el => [el.place_name, el.place_id, el.children, el.place_type_name])))

      }
      )
  }, [token, placeId, getData, dispatch])
  const onClick = (id: string) => {
    dispatch(activeAddressReducer([action[0], id, undefined]))
  }

  const streets = streetItem.map((el, i) => {
    return (
      <div style={el[2] ? { cursor: 'pointer' } : undefined} className={s.list} key={el[1]} onClick={() => {
        onClick(el[1])
      }}>
        <div style={(el[1] === action[1]) ? { backgroundColor: '#2c2c2c', color: '#fff' } : undefined} className={s.listStreets}>
          <span className={s.itemStreet}>{el[3]} {el[0]}
          </span>
        </div>
      </div>)
  })
  return (
    <div className={s.streets}>
      {streets}

    </div>
  )
}

export default Streets