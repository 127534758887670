import React, { useEffect, useState } from 'react'
import s from './adressFilter.module.css'
import { useAppSelector } from '../../../app/Hooks'
import { RiPlayLargeFill } from "react-icons/ri";
import { useGetPlacesMutation } from '../../../app/slices/sliceAddressApi';
import { useDispatch } from 'react-redux';
import { activeAddressReducer, housesReducer, streetsReducer } from '../../../app/slices/adressSlice';
import { setActiveAddreReducer, setNameAddreReducer } from '../../../app/slices/reportSlice';
import Items from './Items';
import { errorsReducer, loadingReducer } from '../../../app/slices/pagesSlice';

type Props = {}


const AddressFilter = (props: Props) => {
    const token = useAppSelector(state => state.login.token)
    const dispatch = useDispatch()
    const { activeCity } = useAppSelector(state => state.address)
    const { activeAddress, namesAddr } = useAppSelector(state => state.report)
    const [getData, { isLoading, isError }] = useGetPlacesMutation()
    const path = ['Населенный пункт', 'Адрес', 'Номер дома']
    const [showItem, setShow] = useState<boolean[]>([false, false, false])




    useEffect(() => {
        dispatch(loadingReducer(isLoading))
    }, [isLoading, dispatch])
    useEffect(() => {
        dispatch(errorsReducer(isError))
    }, [isError, dispatch])
    useEffect(() => {

        (async () => {
            let a: [string, string, string] = [...activeAddress]
            let b: [string, string, string] = [...namesAddr]
            try {

                await getData({ token, placeId: activeAddress[0] }).unwrap()
                    .then(res => {


                        dispatch(streetsReducer(

                            res.data.map(el => [
                                el.place_name, el.place_id, el.children, el.place_type_name])))
                        if (!activeCity[1]) {
                            a.splice(1, 1, res.data[0].place_id)
                            b[1] = res.data[0].place_name

                        }

                    }
                    )

                if (a[1]) await getData({ token, placeId: a[1] }).unwrap()
                    .then(res => {


                        dispatch(housesReducer(res.data.map(el => [el.place_name, el.place_id, el.children, el.place_type_name])))
                        if (!activeCity[2]) {

                            a.splice(2, 1, res.data[0].place_id)
                            b[2] = res.data[0].place_name
                        }
                        dispatch(setActiveAddreReducer([...a]))
                        dispatch(activeAddressReducer([...a]))
                        dispatch(setNameAddreReducer([...b]))
                    }
                    )

            } catch (error) {

            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCity[0], showItem])



    const seacher = path.map((el, i) => {

        return (
            <div key={el + i} className={s.choiceReports}>
                <div className={s.choiceReportsTitle}> {el}
                </div>
                <div className={!showItem[i] ? s.choiceReportsAdress : s.choiceReportsAdressActive}>

                    {!showItem[i] ? <h4 >{namesAddr[i]}</h4> : <div className={s.choiceReports}>
                        <Items setShowSerch={() => { setShow([false, false, false]) }} index={i} /></div>}

                    <RiPlayLargeFill onClick={() => {
                        setShow(prev => prev.map((el, index) => {
                            if (i === index) return !el
                            return false
                        }))
                    }} className={s.iconItems} />
                </div>

            </div>
        )

    })


    return (
        <div className={s.addressFilter}>
            {seacher}
        </div>

    )
}

export default AddressFilter