import React, { useEffect, useState } from 'react'
import s from './flats.module.css'
import { useGetPlacesMutation, useGetSensorsFlatMutation } from '../../../app/slices/sliceAddressApi'
import { TfiPlus } from "react-icons/tfi";
import { TfiMinus } from "react-icons/tfi";
import { useAppDispatch } from '../../../app/Hooks';
import { useSelector } from 'react-redux';
import { selectTFlats } from '../../../app/selectors';
import { setFlatsReducer } from '../../../app/slices/adressSlice';
import PanelRight from '../PanelRight';
import { getIdReducer } from '../../../app/slices/reportSlice';
import { errorsReducer, loadingReducer } from '../../../app/slices/pagesSlice';
type Props = {
  placeId: string | undefined
  token: string | null
  id: string | undefined
}

const Flats = ({ placeId, token, id }: Props) => {
  const dispatch = useAppDispatch()
  const flatsItem = useSelector(selectTFlats)
  const [getData, {isLoading, isError}] = useGetPlacesMutation()
  const [activePlus, setActivePlus] = useState<number | null>(null)
  const [showPanel, setShowPanel] = useState(false)
  const [getSensorsId] = useGetSensorsFlatMutation()

  
  useEffect(() => {
    dispatch(loadingReducer(isLoading))
  }, [isLoading, dispatch])
  useEffect(() => {
    dispatch(errorsReducer(isError))
  }, [isError, dispatch])
  useEffect(() => {
    if (placeId) {
      getData({ token, placeId }).unwrap()
        .then(res => {
          if (res.data.length > 0) {

            dispatch(setFlatsReducer(res.data.map(el =>
              [el.place_name, el.place_id, el.children, el.place_type_name])))
          }
          else dispatch(setFlatsReducer([['', '', false, '']]))

        }
        )
    } else dispatch(setFlatsReducer([['', '', false, '']]))
    setActivePlus(null)
  }, [token, placeId, getData, dispatch])

  const onClick = (sensorType: string, id : string) => {


    if (placeId) {
      getSensorsId({ token, placeId: id }).unwrap()
        .then(res =>{ 
          let a = res.data.parameters.filter(item => item.resource === sensorType)
          if(a.length > 0) {dispatch(getIdReducer({id: a[0].parameter_id, resourse: a[0].resource}))
        setShowPanel(true)}}
        )

    }
  }


  const flats = flatsItem.map((el, i) => {
    return (

      <div className={s.flatsList} key={el[1]}>
        <div className={s.blockFlatsList}>
          <span className={s.flatsEl} >{el[3] + ' '}{el[0]}</span>
          {(i !== activePlus) ? <TfiPlus onClick={() => { setActivePlus(i) }} className={s.iconAddress} /> :
            <TfiMinus className={s.iconAddress} onClick={() => {
              setActivePlus(null)

            }} />}
        </div>

        {(i === activePlus) && <div className={s.flatsDevises}>

          <div className={s.itemsDevisesEl} onClick={() => { onClick('cold', el[1]) }}>
            <span className={s.itemsDevises}>счетчик воды холодный</span>
          </div>
          <div className={s.itemsDevisesEl} onClick={() => { onClick('hot', el[1]) }}>
            <span className={s.itemsDevises}>счетчик воды горячий</span>
          </div>
          <div className={s.itemsDevisesEl}>
            <span className={s.itemsDevises}>счетчик тепла</span>
          </div>
          <div className={s.itemsDevisesEl}>
            <span className={s.itemsDevises}>счетчик электроэнергии</span>
          </div>
        </div>}
      </div>

    )
  })



  return (
    <div className={s.flats}>

      {(flatsItem[0][0].length > 0) && flats}
      {showPanel && <PanelRight close={() => {setShowPanel(false)}}/>}
    </div>

  )
}

export default Flats