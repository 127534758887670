import React, { useEffect, useState } from 'react'
import s from "./dashboard.module.css"
import Maps from './maps/Maps'
//import { RiAlertFill } from "react-icons/ri";
import { TfiSignal } from "react-icons/tfi";
import { TfiBolt } from "react-icons/tfi";
import { PiWaves } from "react-icons/pi";
import { IoWaterOutline } from "react-icons/io5";
import { useGetPlacesMutation, useGetTreeMutation } from '../../app/slices/sliceAddressApi';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import { activeAddressReducer, citesDefaultReducer, citesReducer } from '../../app/slices/adressSlice';
import { errorsReducer, loadingReducer } from '../../app/slices/pagesSlice';
import { setActiveAddreReducer, setNameAddreReducer } from '../../app/slices/reportSlice';

type Props = {}

const Dashboard = (props: Props) => {

    const [getTree, { isLoading, isError }] = useGetTreeMutation()
    const [getCity] = useGetPlacesMutation()
    const { token, user } = useAppSelector(state => state.login)
    const [dashboard, setDashboard] = useState({amount: 0, last:0, gateway: 0})
    const dispatch = useAppDispatch()
    useEffect(() => {
        let a: [string, string, boolean?, boolean?][] = [['', '', false, false]]
        let b = ['', '']
        const getStartData = async () => {
            await getTree({ token }).unwrap()
                .then(res => {

                    a = res.data.map((el) => {

                        if (el.place_type_name !== 'Область') {
                            b = [el.place_name, el.place_id]
                            return [el.place_name, el.place_id, false, true]
                        }
                        else return [el.place_name + ' обл.', el.place_id, true, true]
                    })
                }
                )
                .then(() => {
                    dispatch(citesReducer([...a]))
                    dispatch(citesDefaultReducer([...a]))

                })
            if (b[0].length > 0) {
                dispatch(activeAddressReducer([a[0][1], undefined, undefined]))
                dispatch(setActiveAddreReducer([b[1], '', '']))
                dispatch(setNameAddreReducer([b[0], '', '']))
            } else {
                await getCity({ token, placeId: a[0][1] }).unwrap()
                    .then(res => {
                        dispatch(activeAddressReducer([res.data[0].place_id, undefined, undefined]))
                        dispatch(setActiveAddreReducer([res.data[0].place_id, '', '']))
                        dispatch(setNameAddreReducer([res.data[0].place_name, '', '']))
                    }
                    )
            }
        }

        getStartData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, dispatch, getTree])


    useEffect(() => {
        let a = false
        if (isLoading) a = true
        else a = false
        dispatch(loadingReducer(a))
    }, [isLoading, dispatch])

    useEffect(() => {
        dispatch(errorsReducer(isError))
    }, [isError, dispatch])

    return (
        <div className={s.dashboard}>
            <h2>Организация / {user.firstname} </h2>

            <div className={s.topConteiner}>
                <div className={s.info}>

                    <div className={s.infoItemOne}>
                        <div className={s.infoBlockTop}>
                            <div className={s.infoTitleBlock}>
                                <p className={s.infoTitle}>Базовые станции</p>
                            </div>
                            <div className={s.infoIconBlock}>
                                <TfiSignal className={s.iconStation} />
                            </div>
                        </div>

                        <div className={s.blockStation}>
                            <div className={s.networkStationOne}>
                                <span className={s.infoText}>Всего</span>
                                <span className={s.numberStation}>{dashboard.gateway}</span>
                                
                            </div>

                        </div>

                        <div className={s.batteryPicDash}>
                            <div className={s.batteryLineDash} >
                            </div>
                        </div>
                    </div>

                    <div className={s.infoItem}>
                        <div className={s.infoBlockTop}>
                            <div className={s.infoTitleBlock}>
                                <p className={s.infoTitle}>Приборы учета воды</p>
                            </div>
                            <div className={s.infoIconBlock}>
                                <IoWaterOutline className={s.iconStation} />
                            </div>
                        </div>

                        <div className={s.blockStation}>
                            <div className={s.networkStation}>
                                <span className={s.numberStation}>{dashboard.amount}</span>
                                <span className={s.infoText}>всего</span>
                            </div>
                            <div className={s.networkStation}>
                                <span className={s.slash}>/</span>
                            </div>

                            <div className={s.networkStation}>
                                <span className={s.numberStationOffline}>{dashboard.last}</span>
                                <span className={s.infoText}>опрошенных за 24 часа</span>
                            </div>
                        </div>

                        <div className={s.batteryPicDash}>
                            <div className={s.batteryLineDash} >
                            </div>
                        </div>
                    </div>

                    <div className={s.infoItem}>
                        <div className={s.infoBlockTop}>
                            <div className={s.infoTitleBlock}>
                                <p className={s.infoTitle}>Приборы учета тепла</p>
                            </div>
                            <div className={s.infoIconBlock}>
                                <PiWaves className={s.iconStationHot} />
                            </div>
                        </div>

                        <div className={s.blockStation}>
                            <div className={s.networkStation}>
                                <span className={s.numberStation}>0</span>
                                <span className={s.infoText}>всего</span>
                            </div>
                            <div className={s.networkStation}>
                                <span className={s.slash}>/</span>
                            </div>

                            <div className={s.networkStation}>
                                <span className={s.numberStationOffline}>0</span>
                                <span className={s.infoText}>опрошенных за 24 часа</span>
                            </div>
                        </div>

                        <div className={s.batteryPicDash}>
                            <div className={s.batteryLineDash} >
                            </div>
                        </div>
                    </div>

                    <div className={s.infoItem}>
                        <div className={s.infoBlockTop}>
                            <div className={s.infoTitleBlock}>
                                <p className={s.infoTitle}>Приборы учета электроэнергии</p>
                            </div>
                            <div className={s.infoIconBlock}>
                                <TfiBolt className={s.iconStation} />
                            </div>
                        </div>

                        <div className={s.blockStation}>
                            <div className={s.networkStation}>
                                <span className={s.numberStation}>0</span>
                                <span className={s.infoText}>всего</span>
                            </div>
                            <div className={s.networkStation}>
                                <span className={s.slash}>/</span>
                            </div>

                            <div className={s.networkStation}>
                                <span className={s.numberStationOffline}>0</span>
                                <span className={s.infoText}>опрошенных за 24 часа</span>
                            </div>
                        </div>

                        <div className={s.batteryPicDash}>
                            <div className={s.batteryLineDash} >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.bottomConteiner}>


                <div className={s.message}>
                    <div className={s.messageTopBlock}>
                        <span className={s.messageTitle}>Уведомления</span>
                    </div>
                    <div className={s.messageBlock}>
                        <div className={s.messageIcon}>
                            {//<RiAlertFill className={s.btnIcon} />
                            }
                            <span className={s.messageDate}></span>
                        </div>
                        <div className={s.messageText}>

                            <span className={s.messageTxtOne}></span>

                            <span className={s.messageTxt}>

                           </span>
                        </div>
                    </div>
                    <div className={s.messageBlock}>
                        <div className={s.messageIcon}>

                            <span className={s.messageDate}></span>
                        </div>
                        <div className={s.messageText}>

<span className={s.messageTxtOne}></span>


<span className={s.messageTxt}>

   </span>
</div>
                    </div>

                    <div className={s.messageBlock}>
                        <div className={s.messageIcon}>

                            <span className={s.messageDate}></span>
                        </div>
                        <div className={s.messageText}>

<span className={s.messageTxtOne}></span>


<span className={s.messageTxt}>

    </span>
</div>
                    </div>

                </div>
                <div className={s.map}>
                    <Maps setDashboard={setDashboard}/>
                </div>

            </div>
        </div>



    )
}

export default Dashboard