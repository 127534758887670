import React, { useEffect, useState } from 'react'
import s from './reportWindow.module.css'
import ReportWindowICol from './ReportWindowICol'
import { useGetReportMutation } from '../../../app/slices/sliceLogApi'
import { useAppSelector } from '../../../app/Hooks'
import ReportExcel from '../excel/ReportExcel'
import { useDispatch } from 'react-redux'
import { getOffsetReducer, getReportDataReducer, setActiveAddreReducer } from '../../../app/slices/reportSlice'
import { errorsReducer, loadingReducer } from '../../../app/slices/pagesSlice'



type Props = {}
const ReportWindow = (props: Props) => {
  const token = useAppSelector(state => state.login.token)
  const dispatch = useDispatch()
  const [getReport, { isError }] = useGetReportMutation()
  const id = useAppSelector(state => state.report.activeAddress)
  const { time, offset, data } = useAppSelector(state => state.report)
  const [mainRow, setMainRow] = useState<string[]>(['Адрес', 'Начало периода', 'Конец периода', 'Показание на начало периода',
    'Показание на конец периода', 'Потребление за период', 'Номер договора', 'Лицевой счет', 'Номер счетчика'])
  const [currColumn, setCurrColumn] = useState<number>(0)


  useEffect(() => {
    dispatch(errorsReducer(isError))
  }, [isError, dispatch])

  useEffect(() => {
    return () => { dispatch(setActiveAddreReducer([id[0], '', ''])) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {

    if (id[2].length > 0 && time[0] && time[1] && offset === 0) {



      dispatch(loadingReducer(true))
      getReport({
        token, id: id[2],
        begin: time[0], end: time[1], offset
      }).unwrap()
        .then(res => {
          dispatch(getReportDataReducer([...res.data]))

          return res
        }
        )
        .then((res) => {
          if (res.data.length === 100) dispatch(getOffsetReducer(100))
          else dispatch(loadingReducer(false))
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id[2], time])

  useEffect(() => {
    if ((offset / 100) % 1 === 0 && offset > 0) {


      let a = [...data]


      getReport({
        token, id: id[2],
        begin: time[0], end: time[1], offset
      }).unwrap()
        .then(res => {

          a = [...a, ...res.data]
          dispatch(getOffsetReducer(offset + res.data.length))
          dispatch(getReportDataReducer(a))

        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  const nameFn = () => {
    const a = data[0].address.split(',')
    a.splice(a.length - 1, 1)
    return a.join(',')
  }

  

  const newData = mainRow.map((el, i) => {

    switch (el) {
      case 'Адрес': return data.map(el => el.address)
      case 'Начало периода': return data.map(el => el.begin_date)
      case 'Конец периода': return data.map(el => el.end_date)
      case 'Показание на начало периода': return data.map(el => el.begin_value)
      case 'Показание на конец периода': return data.map(el => el.end_value)
      case 'Потребление за период': return data.map(el => el.volume)
      case 'Номер договора': return data.map(el => el.account)
      case 'Лицевой счет': return data.map(el => el.account)
      default: return data.map(el => el.sensor_number)

    }
  })

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    setCurrColumn(index);

    e.currentTarget.style.cursor = 'grabbing'

  }
  const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.currentTarget.style.cursor = 'grabbing'
  }
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.cursor = 'grabbing'
  }
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.currentTarget.style.cursor = 'grabbing'
  }
  const onDrop = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    let a = [...mainRow]
    let b = a.splice(currColumn, 1)
    a.splice(index, 0, b[0])
    setMainRow(a)
    e.currentTarget.style.cursor = 'grab'
  }

  const columnes = mainRow.map((el, i) => {
    return (
      <div key={el + i} className={s.column}
        style={(el === 'Адрес') ? { width: '40%' } : undefined}
        draggable={true}
        onDragStart={(e) => { onDragStart(e, i) }}
        onDragEnd={(e) => { onDragEnd(e) }}
        onDragLeave={(e) => { onDragLeave(e) }}
        onDragOver={(e) => { onDragOver(e) }}
        onDrop={(e) => { onDrop(e, i) }}

      >
        <div className={s.titleHeaderRow}>
          <p className={s.headerRow}>{el}</p>
        </div>
        <ReportWindowICol index={i} item={newData[i]} />
      </div>
    )
  })




  return (
    <div className={s.blockchoiceReportsBottom}>
      <ReportExcel item={newData} header={mainRow}
        fileName={`Отчет за период ${time[0]}-${time[1]}`}
        name={nameFn() + ` (Отчет за период ${time[0]}-${time[1]})`} />
      <div className={s.itemsReports}>
        <div className={s.address}>
          <div className={s.headerReports}>
            {columnes}
          </div>

        </div>

      </div>
    </div>
  )
}

export default ReportWindow