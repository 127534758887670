import React, { useLayoutEffect, useState } from 'react'
import s from './charts.module.css'
import { useGetReportMutation } from '../../../app/slices/sliceLogApi'
import { useAppDispatch, useAppSelector } from '../../../app/Hooks'
import { getArchiveReducer } from '../../../app/slices/reportSlice'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'График потребления воды за 6 месяцев',
    },
  },
  scales: {
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
    },
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};


const month = new Date().getMonth() + 1
const year = new Date().getFullYear()

const getArray = (() => {

  let a = month - 1
  let b = year
  let c = []

  for (let index = 0; index < 6; index++) {
    if (a > 0) {
      if (a > 9) c.push(['' + a, b])
      else c.push(['0' + a, b])
      a = a - 1
    } else {
      a = 12
      b = year - 1
      c.push(['' + a, b])
      a = a - 1
    }

  }
  return c.reverse()
})()


type Props = {
  id: string
  resource: string
}

const Charts = ({ id, resource }: Props) => {


  const token = useAppSelector(state => state.login.token)
  const [getReport] = useGetReportMutation()
  const [chars, setChars] = useState([0, 0, 0, 0, 0, 0])
  const [labels, setLabels] = useState(['2024-01-31', '2024-01-31', '2024-01-31',
    '2024-01-31', '2024-01-31', '2024-01-31', '2024-01-31'])
  const dispatch = useAppDispatch()
  const request = getArray.map((el, i) => {
    switch (el[0]) {

      case '04':
      case '06':
      case '09':
      case '11':

        return [`${el[1]}-${el[0]}-30`, `${el[1]}-${el[0]}-01`]

      case ('02'):

        return [`${el[1]}-${el[0]}-28`, `${el[1]}-${el[0]}-01`]

      default:
        return [`${el[1]}-${el[0]}-31`, `${el[1]}-${el[0]}-01`]
    }
  })

  useLayoutEffect(() => {
    const fetchData = async () => {
      const a = request.map(el => el[1])
      let b: number[] = [0, 0, 0, 0, 0, 0]

      await getReport({ token, id, begin: request[0][1], end: request[0][0], offset: 0 }).unwrap()
        .then(res => {

          res.data.forEach((el, i) => {

            if (el.resource === resource) {
              b.splice(0, 1, el.volume)


            }

          })

        }


        )

      await getReport({ token, id, begin: request[1][1], end: request[1][0], offset: 0 }).unwrap()

        .then(res => {

          res.data.forEach((el, i) => {
            if (el.resource === resource) b.splice(1, 1, el.volume)

          })
        }
        )

      await getReport({ token, id, begin: request[2][1], end: request[2][0], offset: 0 }).unwrap()
        .then(res => {

          res.data.forEach((el, i) => {
            if (el.resource === resource) b.splice(2, 1, el.volume)

          })
        }
        )

      await getReport({ token, id, begin: request[3][1], end: request[3][0], offset: 0 }).unwrap()
        .then(res => {

          res.data.forEach((el, i) => {
            if (el.resource === resource) b.splice(3, 1, el.volume)

          })
        }
        )

      await getReport({ token, id, begin: request[4][1], end: request[4][0], offset: 0 }).unwrap()
        .then(res => {

          res.data.forEach((el, i) => {
            if (el.resource === resource) b.splice(4, 1, el.volume)

          })
        }
        )

      await getReport({ token, id, begin: request[5][1], end: request[5][0], offset: 0 }).unwrap()
        .then(res => {

          res.data.forEach((el, i) => {
            if (el.resource === resource) {


              b.splice(5, 1, el.volume)


            }
          
          })
        }
        )


      setChars([...b])
      setLabels([...a])



      dispatch(getArchiveReducer({ x: [...a], y: [...b] }))
      b = [0, 0, 0, 0, 0, 0]
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])





  let b = 'rgb(53, 162, 235)'
  if (resource === 'cold') b = 'rgb(53, 162, 235)'
  if (resource === 'hot') b = 'rgb(255, 99, 132)'
  if (resource === 'heat') b = 'rgb(53, 162, 235)'


  const data = {
    labels,
    datasets: [
      {
        label: 'Потребление за месяц',
        data: labels.map((el, i) => {


          return chars[i]
        }),
        borderColor: b,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y',
      },
      /*  {
          label: 'Dataset 2',
          data: labels.map(() => [220, 300, 440, 6]),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          yAxisID: 'y1',
        },*/
    ],
  };

  return (
    <div className={s.charts}>


      <Line options={options} data={data} />


    </div>

  )
}

export default Charts