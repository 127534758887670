import React from 'react'
import s from './prevRP.module.css'
import { useAppSelector } from '../../app/Hooks'
import Charts from './charts/Charts'
type Props = {}

const PrevRP = (props: Props) => {
  const { archive, placeId, resource } = useAppSelector(state => state.report)


  const showArchive = archive.x.map((el, i) => {

    return (
      <div key={el + i} className={s.archive}>
        <div className={s.archiveBlock}>
          <div className={s.date}>
            {el}
          </div>
          <div className={s.value}>
            {archive.y[i]}
          </div>
        </div>

      </div>

    )
  })


  return (
    <div className={s.prev}>{showArchive}
      <Charts id={placeId} resource={resource} />
    </div>
  )
}

export default PrevRP