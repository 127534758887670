import React from 'react'
import s from './reportExcel.module.css'

import ExcelJS  from 'exceljs';


type Props = {
  header: string[]
  item: (number[] | (string | null)[])[]
  fileName: string
  name: string
}

const ReportExcel = ({ item, header, fileName, name }: Props) => {

  const exportExcelFile = () => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Отчет") // создание страницы
    worksheet.properties.defaultRowHeight = 20;




    const rowObj = worksheet.columns = header.map((el, i) => {
      let width = 24
      if (el === 'Адрес') width = 70
      return (
        {
          header: el,
          key: el,
          width
        }
      )
    })
    
    const titleRow = worksheet.insertRow(1, [name]
    )
    
    worksheet.mergeCells(titleRow.number, 1, titleRow.number, 9)
    titleRow.alignment = { horizontal: "center", vertical: "middle" }
  


    titleRow.eachCell((cell: any) => {
      if (cell.col) {
        cell.border = {
          top: { style: "double" },
          left: { style: "double" },
          bottom: { style: "double" },
          right: { style: "double" },
        };
      }
    });
    const title = worksheet.getRow(2)
    title.alignment = { horizontal: "center", vertical: "middle" }
    title.eachCell((cell: any) => {
      if (cell.col) {
        cell.border = {
          top: { style: "double" },
          left: { style: "double" },
          bottom: { style: "double" },
          right: { style: "double" },
        };
      }
    });



    const row = item[0].map((el, i) => {
      const obj = Object.fromEntries(
        rowObj.map((obj, index) => {
          let a = item[index][i]
          if (item[index][i] === null) a = '-'
          return [obj.key, a]
        }
        )
      )


      return { ...obj }
    })

    row.forEach(el => {

      const dataRow = worksheet.addRow(el)
      dataRow.alignment = { horizontal: "center", vertical: "middle" }
      dataRow.eachCell((cell: any) => {

        cell.border = {
          top: { style: "double" },
          left: { style: "double" },
          bottom: { style: "double" },
          right: { style: "double" },
        };

      })

    })


    workbook.xlsx.writeBuffer().then(function (data: any) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${fileName}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });

  }
  return (
    <div className={s.btnExport}><button
      className={s.buttonExport}
      onClick={exportExcelFile}
    >
      Скачать {fileName}
    </button></div>
  )
}

export default ReportExcel