import React from 'react'
import s from './MaintenanceMode.module.css'
import logo from '../img/logo.png'



type Props = {}




const MaintenanceMode = (props: Props) => {


  return (
    <div className={s.maintenanceMode}>
      <div className={s.conteiner}>
      <div className={s.header}>
        <div className={s.logoBlock}>

          <img className={s.logo} src={logo} alt="logo" />

        </div>
        </div>

        <div className={s.errorPage}>

          <div className={s.errorBox}>
            <h2 className={s.titleMaintenanceMode}>Maintenance Mode</h2>
            <div className={s.textBlock}>
              <div className={s.errorText}>
              <p className={s.desc}>Сайт проходит плановое техническое обслуживание.</p>
              <p className={s.desc}>Приносим извинения за неудобства.</p>
            </div>

            <div className={s.contacts}>
              <p className={s.desc}>Контактные данные, если вам нужно связаться:</p>
              <p className={s.desc}>e-mail: info@premexbel.by</p>
              <p className={s.desc}>A1: +375 29 699-54-52 </p>
            </div>
            </div>

            

          </div>
        </div>
      </div>
    </div>
  )
}

export default MaintenanceMode