import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDataReport } from "../interface";

type TArchive = {
    x: string[]
    y: number[]
}
type TReport = {
    archive: TArchive
    id: string
    placeId: string
    resource: string
    activeAddress: [string, string, string]
    namesAddr: [string, string, string]
    time: [string, string]
    offset: number
    data: IDataReport[]
}
const initialState: TReport = {
    archive: {
        x: [''],
        y: [0]
    },
    id: '0485e658-39c8-11ef-8032-0000000000a5',
    placeId:'',
    resource: 'cold',
    activeAddress: ['1d678670-3617-11ef-91b8-0000000004c1', '', ''],
    namesAddr: ['Минск', '', ''],
    time: ['', ''],
    offset: 0,
    data:
    [{
      begin_date: '',
      end_date: '',
      begin_value: 0,
      end_value: 0,
      volume: 0,
      address: '',
      account: '',
      device_type: '',
      resource: '',
      sensor_number: '',
      revers: false
    }]
}

export const reportSlice = createSlice({

    name: 'report',
    initialState,

    reducers: {
        getIdReducer: (state, action: PayloadAction<{ id: string, resourse: string }>) => {
            state.id = action.payload.id
            state.resource = action.payload.resourse
        },
        setPlaceIdReducer: (state, action: PayloadAction<string>) => {
            state.placeId = action.payload
            
        },
        getArchiveReducer: (state, action: PayloadAction<TArchive>) => {
            state.archive = action.payload
        },
        setActiveAddreReducer: (state, action: PayloadAction<[string, string, string]>) => {
            state.activeAddress = [...action.payload]
        },
        setNameAddreReducer: (state, action: PayloadAction<[string, string, string]>) => {
            state.namesAddr = [...action.payload]
        },
        getTimeReducer: (state, action: PayloadAction<[string, string]>) => {
            state.time = [...action.payload]
        },
        getOffsetReducer: (state, action: PayloadAction<number>) => {
            state.offset = action.payload
        },
        getReportDataReducer: (state, action: PayloadAction<IDataReport[]>) => {
            state.data = [...action.payload]
        },
    },
}

)
export const { getArchiveReducer, getIdReducer, setActiveAddreReducer, setPlaceIdReducer,
    setNameAddreReducer, getTimeReducer, getOffsetReducer, getReportDataReducer } = reportSlice.actions;
export default reportSlice.reducer;