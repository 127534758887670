import React, { useEffect, useLayoutEffect, useState } from 'react'
import s from './address.module.css'
import { useGetPlacesMutation } from '../../../app/slices/sliceAddressApi'
import Streets from './Streets'
import Houses from './Houses'
import { useAppDispatch, useAppSelector } from '../../../app/Hooks'
import { activeAddressReducer, citesReducer } from '../../../app/slices/adressSlice'
import { TfiPlus } from "react-icons/tfi";
import { TfiMinus } from "react-icons/tfi";
import Flats from './Flats'
import Search from '../seach/Search'
import SearchSity from '../seach/SearchCity'
import { errorsReducer, loadingReducer } from '../../../app/slices/pagesSlice'


type Props = {}
const Address = (props: Props) => {

  const token = useAppSelector(state => state.login.token)
  const dispatch = useAppDispatch()
  const cities = useAppSelector(state => state.address.cities)
  const action = useAppSelector(state => state.address.activeCity)
  const [showArea, setShowArea] = useState(false)
  const [areaItem, setAreaItem] = useState<[string, string][]>([['', '']])
  const [getData, { isLoading, isError }] = useGetPlacesMutation()
  const [place, setPlace] = useState([action[0], undefined, undefined])

  useLayoutEffect(() => {
    // componentWillUnmount


    dispatch(activeAddressReducer([action[0], undefined, undefined]))
    return () => { dispatch(activeAddressReducer([action[0], undefined, undefined])) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPlace([...action])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action[1], action[2]])



  useEffect(() => {
    let a = false
    if (isLoading) a = true
    else a = false
    dispatch(loadingReducer(a))
  }, [isLoading, dispatch])

  useEffect(() => {
    dispatch(errorsReducer(isError))
  }, [isError, dispatch])
  const plusClick = (id: string, index: number) => {

    if (index === 0) setShowArea(prev => !prev)
    else setShowArea(true)
    let array = [...cities]
    array.splice(index, 1)
    dispatch(citesReducer([cities[index], ...array]))
    getData({ token, placeId: id }).unwrap()
      .then(res => {

        if (cities[index][2]) {
          setAreaItem(res.data.map(el => [el.place_name, el.place_id]))
          dispatch(activeAddressReducer([res.data[0].place_id, undefined, undefined]))
        } else {
          dispatch(activeAddressReducer([id, undefined, undefined]))
        }
      })
  }


  const cityOfAreas = areaItem.map((el, i) => {
    return (
      <span style={(action[0] && action[0] === el[1]) ? { backgroundColor: '#2c2c2c', color: '#fff' } : undefined}
        className={s.cityOfAreas} key={el[1]}
        onClick={() => {
          dispatch(activeAddressReducer([el[1], undefined, undefined]))

        }} >{el[0]}</span>
    )
  })

  const areas = cities.map((el, i) => {
    return (
      <div key={el[0]} className={s.areas} >
        <div className={s.wrapperAreas} style={(i === 0) ? { backgroundColor: '#2c2c2c', color: '#fff' } : undefined}>
          <div className={s.wrapper} style={!el[1] ? { opacity: 0.5 } : undefined


          } >
            <span className={s.region} onClick={() => {
              if (el[3]) { plusClick(el[1], i) }
            }} >{el[0]}</span>
            {(el[0] !== 'Минск' && el[0] !== 'Без адреса') ? <span className={s.listRegion} onClick={() => {
              if (el[3]) { plusClick(el[1], i) }
            }}>{(i === 0 && showArea) ? <TfiMinus className={s.iconAddress} /> : <TfiPlus className={s.iconAddress} />}</span> : <span></span>}
          </div>
        </div>


        {(i === 0 && showArea && (el[0] !== 'Минск' && el[0] !== 'Без адреса')) &&
          <span className={s.itemsCityOfAreas} >
            {cityOfAreas}
          </span>}
      </div>
    )
  })
  const seacher = ['Населенный пункт', 'Адрес', 'Номер дома', 'Номер квартиры / помещения'].map((el, i) => {
    if (i === 0) return <SearchSity key={el + i} name={el} />
    if (i === 1) return <Search key={el + i} index={i} name={el} placeId={action[0]} />
    if (i === 2) return <Search key={el + i} index={i} name={el} placeId={action[1]} />
    if (i === 3) return <Search key={el + i} index={i} name={el} placeId={action[2]} />
    return <Search key={el + i} index={i} name={el} />
  })
  return (
    <div className={s.address}>
      <div className={s.citySelector}>
        <h2>Адреса</h2>
      </div>
      <div className={s.header}>
        {seacher}
      </div>
      <div className={s.conteiner}>
        <div className={s.tableCity}>
          {areas}
        </div>
        <div className={s.streetTable}>
          <Streets token={token} placeId={action[0]} />
        </div>
        <div className={s.houseTable}>
          <Houses token={token} placeId={place[1]} />
        </div>
        <div className={s.flatsTable}>
          <Flats token={token} placeId={place[2]} id={place[1]} />
        </div>
      </div>

    </div>
  )
}

export default Address