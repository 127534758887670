import React from 'react'
import s from './panelRight.module.css'
//import { RiAlertFill } from "react-icons/ri";

type Props = {}

const MessageRP = (props: Props) => {
  return (
    <div className={s.message}>

      <div className={s.messageBlock}>
        <div className={s.messageIcon}>
          {//<RiAlertFill  className={s.btnIcon} />
          }
        </div>
        <div className={s.messageText}>
          <span className={s.messageDate}></span>
          <span className={s.messageTxt}></span>
        </div>
      </div>

      <div className={s.messageBlock}>
        <div className={s.messageIcon}>
       
        </div>
        <div className={s.messageText}>
          <span className={s.messageDate}></span>
          <span className={s.messageTxt}></span>
        </div>
      </div>

      <div className={s.messageBlock}>
        <div className={s.messageIcon}>
          
        </div>
        <div className={s.messageText}>
          <span className={s.messageDate}></span>
          <span className={s.messageTxt}></span>
        </div>
      </div>
    </div>
  )
}

export default MessageRP