import React from 'react'
import s from './error500.module.css'
import monitor from '../img/monitor.png'
import logo from '../img/logo.png'
import NavBar from './NavBar'
import { useNavigate } from 'react-router-dom'

type Props = {}




const Error500 = (props: Props) => {
const navigate = useNavigate()

  return (
    <div className={s.error500}>
      <div className={s.conteiner}>
       
      <div className={s.header}>
        <div className={s.logoBlock}>

          <img className={s.logo} src={logo} alt="logo" />

        </div>
      </div>
        <div className={s.errorPage}>
          <NavBar  />
        <div className={s.errorPageBlock}>
             <div className={s.errorNumber}>
             
              <img className={s.pic} src={monitor} alt="pic" />
             
           </div>
           <div className={s.errorText}>
            <p className={s.desc}>На сервере произошла непредвиденная ошибка.</p>
            <p className={s.desc}>Пожалуйста, подождите, в ближайшее время она будет исправлена.</p>
           </div>
           <button className={s.btnExit} onClick={()=> navigate('/')}>Вернуться</button>
        </div>
        
        </div>

      </div>
    </div>
  )
}

export default Error500