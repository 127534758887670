import React, { useEffect, useState } from 'react'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import s from './maps.module.css'
import { useGetMapMutation } from '../../../app/slices/sliceAddressApi';
import { IMap } from '../../../app/interface';
import { useAppSelector } from '../../../app/Hooks';

type TDashboard = {
  amount: number
  last: number
  gateway: number
}
type Props = {
  setDashboard: ({ amount, last }: TDashboard) => void
}
let total = 2000

const Maps = ({ setDashboard }: Props) => {
  const token = useAppSelector(state => state.login.token)
  const [getMap, { isLoading }] = useGetMapMutation()
  const [placeMark, setPlaceMark] = useState<IMap[]>([{
    id: 'map',
    location: {
      latitude: 53.89385,
      longitude: 27.60526,
      altitude: 0,
      accuracy: 0,
    }
  }])
  useEffect(() => {

    if (token) getMap({ token, total }).unwrap()
      .then(res => {

        setDashboard({
          amount: res.amount,
          last: res.last,
          gateway: res.gateways.length
        })
        setPlaceMark([...res.gateways])

      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMap, token])



  const marks = placeMark.map((el, i) => {

    return (
      <Placemark key={el.id} defaultGeometry={[el.location.latitude, el.location.longitude]} />

    )

  })

  return (
    <>
      {isLoading ? <div></div> :
        <YMaps >
          <div className={s.mapTopBlock}>
            <span className={s.mapTitle}>Базовые станции</span>
          </div>

          <div className={s.mapBottomBlock}>
            {/*<div className={s.mapBlockLeft}>
              <div className={s.stationOnline}>
                <TfiSignal className={s.iconMapStation} />
                <span className={s.onlineTxt}>в сети</span>
              </div>

              <div className={s.stationOffline}>
                <TfiSignal className={s.iconMapStationOffline} />
                <span className={s.offlineTxt}>не в сети</span>
              </div>

            </div>*/}
            <div className={s.mapBlockRight}>
              <Map width={1200} height={700} defaultState={{ center: [53.95, 27.57], zoom: 7 }} >
                {marks}
              </Map>
            </div>
          </div>

        </YMaps>}

    </>
  )
}

export default Maps