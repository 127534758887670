import React from 'react'
import s from './comingSoon.module.css'
import logo from '../img/logo.png'
import time from '../img/Group 67.png'


type Props = {}




const ComingSoon = (props: Props) => {


  return (
    <div className={s.comingSoon}>
      <div className={s.conteiner}>
        
      <div className={s.header}>
        <div className={s.logoBlock}>

          <img className={s.logo} src={logo} alt="logo" />

        </div>
        </div>

        <div className={s.comingSoonPage}>

          <div className={s.comingSoonBox}>
           
            <div className={s.textBlock}>
              <div className={s.comingSoonTextTop}>
              <p className={s.desc}>Наш новый проект</p>
              </div>
                <h2 className={s.titleComingSoon}>Coming soon</h2>
            <div className={s.comingSoonTextBottom}>
              <p className={s.desc}> Cледите за обновлениями</p>
             
            </div>
            </div>
            
            </div>
            <div className={s.timeBlock}>
       
       <img className={s.time} src={time} alt="logo" />
     </div>


          </div>
        </div>
      </div>
  
  )
}

export default ComingSoon