import React, { useState } from 'react'
import s from './login.module.css'
import { FaTimes } from "react-icons/fa";
import Reg from './Reg';
import { useGetTokenMutation } from '../../app/slices/sliceLogApi';
import { useAppDispatch } from '../../app/Hooks';
import { clearTokenReducer, tokenReducer } from '../../app/slices/loginSlice';
import { useNavigate } from 'react-router-dom';
import logo from '../../img/logo.png'
type Props = {}

const Login = (props: Props) => {
    const [reg, setReg] = useState(false)
    const [valueLog, setValueLog] = useState<string | undefined>('')
    const [valuePass, setValuePass] = useState<string | undefined>('')
    const [giveLogin, { isLoading }] = useGetTokenMutation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const exit = () => {
        dispatch(clearTokenReducer())
    }

    const handleSubmit =
        (e: React.FormEvent<HTMLFormElement>) => {

            e.preventDefault()

            giveLogin({ username: valueLog, password: valuePass })
                .then(response => {

                    if (response.data) {
                   
                    console.log(response);
                    
                        if (response.data.token) localStorage.setItem('app_premex', response.data.token)
                        dispatch(tokenReducer({ user: { ...response.data.user }, token: response.data.token, expires: response.data.expires }))
                       

                    }
                })
                .then(() => {navigate('/main')})
                .catch(error => {
                    console.log(error)
                    exit()
                })
        }

    return (
        <div className={s.login} >

            {!isLoading && <div className={s.formlog}>
                <FaTimes className={s.close} onClick={() => { setReg(false) }} />
                <div className={s.logoBlock}>

                    <img className={s.logo} src={logo} alt="logo" />

                </div>
                {reg ? <Reg /> :
                    <div className={s.formLog}>
                        <form onSubmit={handleSubmit} className={s.inputLog}>

                            <input type="email" value={valueLog}
                                placeholder='эл. почта'
                                required
                                onChange={(e) => { setValueLog(e.currentTarget.value) }}
                            />

                            <input type="password"
                                value={valuePass}
                                placeholder='пароль'
                                size={7}
                                minLength={5}
                                maxLength={20}
                                onChange={(e) => { setValuePass(e.currentTarget.value) }}
                                required
                            />
                            <button className={s.loginBtn} type='submit' >Войти</button>

                        </form>

                    </div>

                }

            </div>}
        </div>


    )
}

export default Login