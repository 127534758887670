
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';

type Selector<S> = (state: RootState) => S;

export const dataSelector = (state: RootState) => {
  return state.data.data
}

//address

export const selectFlats = (state: RootState) => state.address.flats
export const selectTypeFlat = (state: RootState) => state.address.typeFlat
export const selectActiveSity = (state: RootState) => state.address.activeCity
const selectCities = (state: RootState) => state.address.cities
const selectStreets = (state: RootState) => state.address.streets
const selectHouses = (state: RootState) => state.address.houses

export const selectTFlats: Selector<[string, string, boolean, string][]> = createSelector(
  [selectFlats, selectTypeFlat], (flats, typeFlats) => {

    if (flats[0][0].length > 0 && typeFlats === 'Квартира') {
      let a = flats.filter(el => el[3] === typeFlats)
      if (a.length === 0) return [['', '', false, '']]
      else return a
    }
    if (flats[0][0].length > 0 && typeFlats === 'Офис') {
      let a = flats.filter(el => el[3] === typeFlats)
      if (a.length === 0) return [['', '', false, '']]
      else return a
    }
    if (flats[0][0].length > 0 && typeFlats === 'Empty') {
      let a = flats.filter(el => (el[3] !== 'Офис' && el[3] !== 'Квартира' ))
      if (a.length === 0) return [['', '', false, '']]
      else return a
    }
    return [['', '', false, '']]

  }

)
