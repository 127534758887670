import React from 'react'
import s from './error404.module.css'
import counter from '../img/Group 66.png'
//import logo from '../img/logo.png'
//import NavBar from './NavBar'

type Props = {}


const Error404 = (props: Props) => {


  return (
    <div className={s.error404}>
      <div className={s.conteiner}>


     {/* <div className={s.header}>
        <div className={s.logoBlock}>

          <img className={s.logo} src={logo} alt="logo" />

        </div>
        </div>*/}
        <div className={s.error404Block}>
         {/* <NavBar />*/}
          <div className={s.errorPage}>
            <div className={s.errorNumber}>

              <img className={s.pic} src={counter} alt="pic" />

            </div>
            <div className={s.errorText}>
              <p className={s.desc}>Кажется что-то пошло не так!</p>
              <p className={s.desc}>Страница, которую вы запрашиваете, не существует.</p>
            </div>
            <button className={s.btnExit}>Вернуться</button>
          </div>
        </div>




      </div>
    </div>
  )
}

export default Error404