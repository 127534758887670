import React from 'react'
import s from './reportWindow.module.css'

type Props = {
    index: number
    item: Array<string|number|null>
}

const ReportWindowICol = ({index, item}: Props) => {

     
    const items = item.map((el, i) => {
     return   <p className={s.headerRow} key={''+ el + i*2 + index
     }>{el}</p>
    })
  return (
    <div className={s.itemRow}>
        {items} 
    </div>
  )
}

export default ReportWindowICol