import React, { useEffect } from 'react'
import s from './reports.module.css'
import { useAppSelector } from '../../../app/Hooks'
import { useDispatch } from 'react-redux'
import { useGetPlacesMutation } from '../../../app/slices/sliceAddressApi'
import Search from '../seach/Search'
import SearchSity from '../seach/SearchCity'
import { getOffsetReducer, setActiveAddreReducer, setNameAddreReducer } from '../../../app/slices/reportSlice'
import { activeAddressReducer, housesReducer } from '../../../app/slices/adressSlice'
import { errorsReducer, loadingReducer } from '../../../app/slices/pagesSlice'


type Props = {
  index: number
  setShowSerch: () => void
}

const Items = ({ index, setShowSerch }: Props) => {
  const token = useAppSelector(state=> state.login.token)
  const { cities, streets, houses, activeCity } = useAppSelector(state => state.address)
  const { namesAddr } = useAppSelector(state => state.report)
  const dispatch = useDispatch()
  const [getData, {isLoading, isError}] = useGetPlacesMutation()

  
  useEffect(() => {
    dispatch(loadingReducer(isLoading))
  }, [isLoading, dispatch])
  useEffect(() => {
    dispatch(errorsReducer(isError))
  }, [isError, dispatch])
  const item = [cities, streets, houses]

  const onclick = async (id: string) => {
     dispatch(getOffsetReducer(0))
    if (index === 0) {
      dispatch(setActiveAddreReducer([id, '', '']))
      dispatch(activeAddressReducer([id, undefined, undefined]))
      let a = cities.filter(el => el[1] === id)[0][0]
      if (a) dispatch(setNameAddreReducer([a, '', '']))
      setShowSerch()
    }
    if (index === 1) {
      await getData({ token, placeId: id }).unwrap()
        .then(res => {
          dispatch(setActiveAddreReducer([activeCity[0], id, res.data[0].place_id]))
          dispatch(activeAddressReducer([activeCity[0], id, res.data[0].place_id]))
          dispatch(housesReducer(res.data.map(el => [el.place_name, el.place_id, el.children, el.place_type_name])))
          let a = streets.filter(el => el[1] === id)[0][0]
          if (a) dispatch(setNameAddreReducer([namesAddr[0], a, res.data[0].place_name]))
        })

      setShowSerch()

    }
    if (index === 2) {

      if(activeCity[1]) dispatch(setActiveAddreReducer([activeCity[0], activeCity[1], id]))
      dispatch(activeAddressReducer([activeCity[0], activeCity[1], id]))
      let a = houses.filter(el => el[1] === id)[0][0]
      if (a) dispatch(setNameAddreReducer([namesAddr[0], namesAddr[1], a]))
      setShowSerch()
    }
  }


  const show = item[index].map((el, i) => {
    return <h4 className={s.wrShh4} key={el[1] + i} onClick={() => {

      onclick(el[1])
    }}>{el[0]}</h4>
  })


  return (
    <div>

      {(index === 0) ?
        <div className={s.searchSity}>
          <SearchSity name='' show={true} />
        </div>
        :
        <div className={s.searchAktiv}>
          <Search placeId={activeCity[index - 1]} name='' index={index} show={true} />
        </div>


      }
      <div className={s.wrapperShow}>
        {show}
      </div>



    </div>
  )
}

export default Items